import React from 'react';
import { ICallToAction } from '@cds/search-client';

import { WidgetName } from '@utils/snowplowTracking';

import { WidgetButton } from './WidgetButton';

export interface GenericWidgetProps {
  ctas: ICallToAction[];
  secondary: boolean;
  widgetName: WidgetName;
}

export default function GenericWidget({
  ctas,
  secondary,
  widgetName,
}: GenericWidgetProps): React.ReactElement {
  return (
    <>
      <WidgetButton
        label={ctas[0].cta.trim()}
        url={ctas[0].url}
        secondary={secondary}
        widgetName={widgetName}
      />
    </>
  );
}
