"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.uml = exports.umlautsReplacements = exports.umlauts = void 0;
exports.slugify = slugify;
exports.removeLeadingSlash = removeLeadingSlash;
exports.ensureTrailingSlash = ensureTrailingSlash;
exports.umlauts = 'äöüß';
exports.umlautsReplacements = ['ae', 'oe', 'ue', 'ss'];
exports.uml = new RegExp(exports.umlauts.split('').join('|'), 'g');
function slugify(str, language = 'de') {
    const a = 'àáäâãåăæçèéëêǵḧìíïîḿńǹñòóöôœøṕŕśșțùúüûǘẃẍÿź·/_,:;';
    const b = 'aaaaaaaaceeeeghiiiimnnnooooooprsstuuuuuwxyz------';
    const p = new RegExp(a.split('').join('|'), 'g');
    return str
        .toString()
        .toLowerCase()
        .replace(/\s+/g, '-') // Replace spaces with -
        .replace(exports.uml, (c) => {
        return exports.umlautsReplacements[exports.umlauts.indexOf(c)];
    }) // replace umlauts
        .replace(p, (c) => {
        return b.charAt(a.indexOf(c));
    }) // Replace special characters
        .replace(/&/g, language === 'de' ? '-und-' : '-and-') // Replace &
        .replace(/[^\w\-]+/g, '') // Remove all non-word characters
        .replace(/\-\-+/g, '-') // Replace multiple - with single -
        .replace(/^-+/, '') // Trim - from start of text
        .replace(/-+$/, ''); // Trim - from end of text
}
function removeLeadingSlash(url) {
    if (url.startsWith('/')) {
        return url.substring(1);
    }
    return url;
}
function ensureTrailingSlash(url) {
    if (url === '' || url.endsWith('/')) {
        return url;
    }
    return `${url}/`;
}
