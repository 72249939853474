import create from 'zustand';
import {
  StartResponse,
  StateChangedMessage,
  TextMessage,
} from '@cds/chatbot-client';

export type UserStateType = {
  participantId: string;
  displayName: string;
  hasConsented: boolean;
  status: 'online' | 'offline' | 'reconnected';
  state: -1 | StateChangedMessage['state'];
};

type QuickReplyContent = {
  id: string;
  type: string;
  action: string;
  text: string;
};

// THIS IS A PLACEHOLDER
export type StructuredMessage = {
  __type: string;
  text: string;
  contentType: string;
  content: QuickReplyContent[];
  timestamp: string;
  participantId: string;
  sequenceNumber: number;
};

export type ChatMessageType = TextMessage & {
  displayName: string;
  renderAt?: number;
};

export type AgentState = {
  agents: Record<string, string>;
  state: number;
};

export type ChatStateState = StartResponse & {
  workgroupName?: string;
};

export type ChatState = {
  chatState: ChatStateState;
  agentState: AgentState;
  userState: UserStateType;
  msgs: ChatMessageType[];
  temporaryMsgs: ChatMessageType[];
  unsentMsgs: ChatMessageType[];
  quickReplies: QuickReplyContent[];
  isChatOpen: boolean;
  isChatMinimized: boolean;
  isChatBotTyping: boolean;
  lastUserMessageTimestamp: number;
  unreadMsgs: number;
  setChatState: (state: Partial<ChatStateState>) => void;
  setAgentState: (state: AgentState) => void;
  setUser: (state: Partial<UserStateType>) => void;
  setMsgs: (msgs: ChatMessageType[]) => void;
  setTemporaryMsgs: (temporaryMsgs: ChatMessageType[]) => void;
  setUnsentMsgs: (temporaryMsgs: ChatMessageType[]) => void;
  setQuickReplies: (qr: QuickReplyContent[]) => void;
  setIsChatOpen: (b: boolean) => void;
  setIsChatMinimized: (b: boolean) => void;
  setIsChatBotTyping: (b: boolean) => void;
  updateLastUserMessageTimestamp: () => void;
  setUnreadMsgs: (n: number) => void;
};

export const useChatStore = create<ChatState>((set) => ({
  chatState: {
    chatId: '',
    pollWaitSuggestion: undefined,
    workgroupName: undefined,
  },
  agentState: {
    agents: {
      '00000000-0000-0000-0000-000000000000': 'SYSTEM',
    },
    state: 0,
  },
  userState: {
    participantId: '',
    displayName: '',
    hasConsented: false,
    status: 'online',
    state: 0, // -1 error connecting / 0 - connecting / 1 - alerted about chat session / 2 - active / 3 - on hold / 4 - disconnected
  },
  msgs: [],
  temporaryMsgs: [],
  unsentMsgs: [],
  quickReplies: [],
  isChatMinimized: false,
  isChatBotTyping: false,
  lastUserMessageTimestamp: 0,
  unreadMsgs: 0,
  isChatOpen: false,
  setChatState: (chatState) =>
    set((state) => ({ chatState: { ...state.chatState, ...chatState } })),
  setAgentState: (agentState) => set(() => ({ agentState })),
  setUser: (userState) =>
    set((state) => ({ userState: { ...state.userState, ...userState } })),
  setMsgs: (msgs) => {
    const minimumInitialDelay = 800;
    const messageInterval = 150;

    const state = useChatStore.getState();
    const now = new Date().getTime();
    const delay = Math.max(
      0,
      state.lastUserMessageTimestamp + minimumInitialDelay - now,
    );
    setTimeout(() => set(() => ({ isChatBotTyping: false })), delay);

    const staggeredMsgs = msgs.map((msg, i) => ({
      ...msg,
      renderAt: now + delay + i * messageInterval,
    }));
    set((state) => ({ msgs: [...state.msgs, ...staggeredMsgs] }));
  },
  setTemporaryMsgs: (temporaryMsgs) => set(() => ({ temporaryMsgs })),
  setUnsentMsgs: (unsentMsgs) => set(() => ({ unsentMsgs })),
  setQuickReplies: (quickReplies) => set(() => ({ quickReplies })),
  setIsChatOpen: (b) => set(() => ({ isChatOpen: b })),
  setIsChatMinimized: (b) => set(() => ({ isChatMinimized: b })),
  setIsChatBotTyping: (b) => set(() => ({ isChatBotTyping: b })),
  updateLastUserMessageTimestamp: () =>
    set(() => ({ lastUserMessageTimestamp: new Date().getTime() })),
  setUnreadMsgs: (n) => set(() => ({ unreadMsgs: n })),
}));

export const selectChatProps = (state: ChatState) => ({
  chatState: state.chatState,
  agentState: state.agentState,
  userState: state.userState,
  msgs: state.msgs,
  temporaryMsgs: state.temporaryMsgs,
  unsentMsgs: state.unsentMsgs,
  quickReplies: state.quickReplies,
  isChatOpen: state.isChatOpen,
  isChatMinimized: state.isChatMinimized,
  isChatBotTyping: state.isChatBotTyping,
  unreadMsgs: state.unreadMsgs,
});

export const selectChatActions = (state: ChatState) => ({
  setChatState: state.setChatState,
  setAgentState: state.setAgentState,
  setUser: state.setUser,
  setMsgs: state.setMsgs,
  setTemporaryMsgs: state.setTemporaryMsgs,
  setUnsentMsgs: state.setUnsentMsgs,
  setQuickReplies: state.setQuickReplies,
  setIsChatOpen: state.setIsChatOpen,
  setIsChatMinimized: state.setIsChatMinimized,
  setIsChatBotTyping: state.setIsChatBotTyping,
  updateLastUserMessageTimestamp: state.updateLastUserMessageTimestamp,
  setUnreadMSgs: state.setUnreadMsgs,
});
