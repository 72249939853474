import { keyframes, styled } from '@neui/core';

export default function BeneSVG() {
  return (
    <Wrapper
      width="376"
      height="240"
      viewBox="0 0 376 240"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
    >
      <g clipPath="url(#clip0_233_3890)">
        <path
          opacity="0.2"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M119.924 72.3702C118.404 63.7502 110.964 57.4902 102.224 57.4902C101.174 57.4902 100.114 57.5902 99.0742 57.7702C89.3042 59.4902 82.7542 68.8502 84.4742 78.6202C85.9942 87.2402 93.4342 93.5002 102.174 93.5002C103.224 93.5002 104.284 93.4002 105.324 93.2202C110.064 92.3902 114.184 89.7602 116.944 85.8202C119.704 81.8802 120.754 77.1002 119.924 72.3702ZM98.3742 53.8302C99.6742 53.6002 100.954 53.4902 102.224 53.4902C112.694 53.4902 121.984 61.0002 123.874 71.6602C125.984 83.6302 117.994 95.0402 106.024 97.1502C104.724 97.3802 103.444 97.4902 102.174 97.4902C91.6942 97.4902 82.4142 89.9802 80.5342 79.3102C78.4242 67.3502 86.4142 55.9402 98.3742 53.8302ZM111.936 75.125L111.386 76.9835L111.386 76.9845C110.328 80.5482 107.344 83.2118 103.674 83.8632C100.01 84.5049 96.2901 83.0282 94.0805 80.0388L92.9179 78.4753L90.5105 80.2654L91.6679 81.8219L91.6692 81.8237C94.5594 85.7326 99.4175 87.6553 104.193 86.8178L104.196 86.8173C108.984 85.9683 112.88 82.4926 114.262 77.8372L114.813 75.9757L111.936 75.125ZM107.448 64.9124C109.217 64.6054 111 65.3221 112.067 66.7543L112.071 66.7591L113.541 68.7491L111.128 70.5316L109.66 68.5443C109.266 68.0179 108.611 67.7554 107.961 67.8682C107.317 67.9799 106.791 68.4486 106.602 69.0781L105.903 71.4452L103.026 70.5954L103.727 68.2204C104.238 66.511 105.672 65.2206 107.448 64.9124ZM98.2772 69.1942C97.2104 67.762 95.4268 67.0453 93.6578 67.3524C91.8818 67.6606 90.4486 68.9509 89.9372 70.6604L89.2357 73.0354L92.1128 73.8852L92.812 71.5181C93.0012 70.8886 93.5275 70.4198 94.1708 70.3082C94.8209 70.1953 95.4765 70.4578 95.8698 70.9843L97.3378 72.9715L99.7508 71.189L98.2808 69.199L98.2772 69.1942Z"
          fill="#002E3C"
        />
        <path
          opacity="0.2"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M262.044 98.8498C260.454 98.8498 258.944 99.8098 258.324 101.38L250.964 119.98C250.154 122.03 251.154 124.36 253.214 125.17L256.934 126.64L258.094 140L268.094 131.05L282.974 136.94C283.454 137.13 283.954 137.22 284.444 137.22C286.034 137.22 287.544 136.26 288.164 134.69L295.524 116.09C296.334 114.04 295.334 111.71 293.274 110.9L263.524 99.1198C263.044 98.9298 262.544 98.8398 262.054 98.8398L262.044 98.8498ZM262.054 102.84L291.804 114.62L284.444 133.22L269.564 127.33L267.264 126.42L265.424 128.07L261.384 131.68L260.914 126.28L260.704 123.82L258.404 122.91L254.684 121.44L262.044 102.84"
          fill="#002E3C"
        />
        <g className="bot">
          <path
            d="M165.627 99.0008C166.617 97.8208 168.187 96.4708 170.167 95.1208C172.947 93.2308 175.497 92.2308 177.297 91.8008"
            stroke="white"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            d="M158.427 104V144L170.427 148H194.427L206.427 144V104C203.097 98.67 195.227 88 182.427 88C169.627 88 160.427 98.67 158.427 104Z"
            fill="#FFD700"
          />
          <path
            d="M206.427 120.02V103.99C203.097 98.6602 195.227 87.9902 182.427 87.9902C169.627 87.9902 160.427 98.6602 158.427 103.99V120.02H206.427Z"
            fill="#FFD700"
          />
          <path
            opacity="0.5"
            d="M206.427 120.02V103.99C203.097 98.6602 195.227 87.9902 182.427 87.9902C169.627 87.9902 160.427 98.6602 158.427 103.99V120.02H206.427Z"
            fill="white"
          />
          <path
            d="M208.127 102.94C201.677 92.61 193.517 86.83 184.427 86.09V76H180.427V86.09C167.767 87.11 158.707 97.57 156.557 103.3L156.427 103.64V144C156.427 144.86 156.977 145.62 157.797 145.9L166.427 148.78V144.56L160.427 142.56V122.03H204.427V142.56L198.437 144.56V148.78L207.057 145.91C207.877 145.64 208.427 144.87 208.427 144.01V103.42L208.127 102.93V102.94ZM204.427 118.02H160.427V104.38C162.527 99.33 171.077 90 182.427 90C192.837 90 200.097 97.81 204.427 104.58V118.03V118.02Z"
            fill="black"
          />
          <path
            d="M170.427 104C172.637 104 174.427 105.79 174.427 108C174.427 110.21 172.637 112 170.427 112C168.217 112 166.427 110.21 166.427 108C166.427 105.79 168.217 104 170.427 104Z"
            fill="black"
          />
          <path
            d="M194.427 104C196.637 104 198.427 105.79 198.427 108C198.427 110.21 196.637 112 194.427 112C192.217 112 190.427 110.21 190.427 108C190.427 105.79 192.217 104 194.427 104Z"
            fill="black"
          />
          <path d="M194.427 146H170.427V158H194.427V146Z" fill="#FFC700" />
          <path d="M194.427 146H182.417V158H194.427V146Z" fill="#F49300" />
          <path
            d="M194.427 146H170.427V158H178.427V162H186.427V158H194.427V146ZM190.427 154H174.427V150H190.427V154Z"
            fill="black"
          />
          <path
            d="M198.417 144.56L197.787 142.66L202.427 141.1L202.417 126.15H204.417L204.427 142.54L198.417 144.56Z"
            fill="#F49300"
          />
          <path
            opacity="0.5"
            d="M162.457 122.07H160.457V138.07H162.457V122.07Z"
            fill="white"
          />
          <path
            opacity="0.5"
            d="M175.437 150V153.99"
            stroke="white"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            d="M154.427 119.23L144.427 122.56V140H148.427V125.44L154.427 123.44V119.23Z"
            fill="black"
          />
          <path
            opacity="0.5"
            d="M164.287 102.02L162.667 100.85C163.327 99.9297 164.067 99.0497 164.837 98.2097C166.227 96.7197 167.817 95.3597 169.547 94.1797C170.917 93.2497 172.787 91.9697 176.207 90.9297L176.787 92.8397C173.657 93.7997 172.007 94.9197 170.677 95.8297C169.067 96.9297 167.597 98.1797 166.307 99.5697C165.587 100.34 164.907 101.17 164.287 102.02Z"
            fill="white"
          />
          <path
            d="M166.337 99.5399L164.807 98.2499C165.937 96.8999 167.867 95.3499 168.827 94.6499C170.437 93.4699 171.727 92.8199 172.587 92.3899L173.017 92.1699L173.947 93.9399L173.487 94.1699C172.687 94.5699 171.487 95.1799 170.017 96.2599C168.867 97.0999 167.227 98.4799 166.347 99.5299L166.337 99.5399Z"
            fill="white"
          />
          <path
            d="M210.497 119.25V123.46L216.427 125.44V140H220.427V122.56L210.497 119.25Z"
            fill="black"
          />
        </g>
        <path
          opacity="0.3"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M140.432 178H144.432V182H140.432V178ZM212.427 178H148.427V182H212.427V178ZM224.422 178H216.422V182H224.422V178Z"
          fill="#002E3C"
          id="shadow"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M210.39 80.1043L211.886 70.8242L215.835 71.461L214.339 80.7411L210.39 80.1043ZM228.556 80.598L220.935 86.1019L223.278 89.3445L230.898 83.8406L228.556 80.598ZM226.288 97.2901L225.651 101.239L234.931 102.736L235.568 98.7865L226.288 97.2901Z"
          fill="black"
          className="bot"
        />
      </g>
      <defs>
        <clipPath id="clip0_233_3890">
          <rect
            width="375"
            height="240"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </Wrapper>
  );
}

const upAndDown = keyframes({
  '0%': { transform: 'translateY(0px)' },
  '25%': { transform: 'translateY(7px)' },
  '55%': { transform: 'translateY(-13px)' },
  '80%': { transform: 'translateY(7px)' },
  '100%': { transform: 'translateY(0px)' },
});

const colorChange = keyframes({
  '0%': { opacity: 0.3 },
  '25%': { opacity: 0.4 },
  '55%': { opacity: 0.2 },
  '80%': { opacity: 0.4 },
  '100%': { opacity: 0.3 },
});

const Wrapper = styled('svg', {
  '& .bot': {
    animation: `${upAndDown} 5s infinite cubic-bezier(0.45, 0.00, 0.67, 1.00)`,
  },
  '& #shadow': {
    animation: `${colorChange} 5s infinite cubic-bezier(0.45, 0.00, 0.67, 1.00)`,
  },
});
