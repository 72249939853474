import { useRef, useCallback, useEffect } from 'react';
import { styled } from '@neui/core';

import { Image } from '@components/Image';

export function useSearchRef(isSearchPage: boolean) {
  const openSearch = useRef(() => {});
  const closeSearch = useRef(() => {});

  useEffect(() => {
    if (isSearchPage) {
      openSearch.current();
    }
  }, []);

  const setOpenSearch = useCallback(
    (f: () => void) => (openSearch.current = f),
    [],
  );

  const setCloseSearch = useCallback(
    (f: () => void) => (closeSearch.current = f),
    [],
  );

  return { openSearch, closeSearch, setOpenSearch, setCloseSearch };
}

export const OnlineBankingIcon = () => {
  return (
    <IconContainer>
      <Image src="/icons/laptop-logo.svg" alt="" />
      <Image src="/icons/smartphone-logo.svg" alt="" />
    </IconContainer>
  );
};

const IconContainer = styled('div', {
  ':nth-child(1)': {
    display: 'none',
  },
  ':nth-child(2)': {
    display: 'block',
  },
  '@md': {
    ':nth-child(1)': {
      display: 'block',
    },
    ':nth-child(2)': {
      display: 'none',
    },
  },
});
