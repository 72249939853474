import { styled } from '@neui/core';
import { Box } from '@neui/layout';

export const Underline = styled(Box, {
  backgroundColor: '$text-helper',
  height: 1,
  transition: 'all 0.1s ease',
  variants: {
    active: {
      true: {
        color: '$text-standard',
        backgroundColor: '$text-standard',
        transform: 'scaleY(1.5)',
      },
    },
    focused: {
      true: {
        color: '$primary',
        backgroundColor: '$primary',
        transform: 'scaleY(1.5)',
      },
    },
    invalid: {
      true: {
        backgroundColor: '$error !important',
      },
    },
    disabled: {
      true: {
        backgroundColor: '$text-disabled !important',
      },
    },
  },
});
