import { BannerTextBadgeProps } from '@components/neui-components/atoms/BannerTextBadge';

import type { AppSettings } from './config';

type BadgeRule = {
  rules: RegExp[];
  attrs: BannerTextBadgeProps;
};

function getBadgeRules(
  portal: string,
  language: AppSettings['site']['language'],
): BadgeRule[] {
  const rules: Record<string, BadgeRule[]> = {
    puk: [
      {
        rules: [/\/produkte\//, /\/firmenkunden\/produkte-4\//],
        attrs: {
          title: language === 'de' ? 'Produkt' : 'Product',
          look: 'primary',
        },
      },
      {
        rules: [
          /^.*ccb-tuc\d{2}\.cloud\.internal(:\d+)?(\/service\/?.*)?$/,
          /^.*ccb-twgi\.intranet\.commerzbank\.com(:\d+)?(\/service\/?.*)?$/,
          /commerzbank.de\/service\//,
          /\/gpp-hilfe\//,
          /^https:\/\/cds-wissen/,
          /^https:\/\/service-firmenkunden\.commerzbank\.(de|com)\/.*$/,
          /^https:\/\/service-corporates\.commerzbank\.(de|com)\/.*$/,
          /localhost(:\d+)?\/.*$/,
        ],
        attrs: {
          title: 'FAQ',
          look: 'highlight',
        },
      },
      // {
      //   rules: [/^https:\/\/filialsuche/, /\/filialen\//],
      //   attrs: {
      //     title: 'Filiale',
      //     look: 'optional',
      //   },
      // },
      // {
      //   rules: [/\/blog\//, /\/wissen\//, /\/ratgeber\//, /\/themen\//],
      //   attrs: {
      //     title: 'Artikel',
      //     look: 'secondary-4',
      //   },
      // },
      // {
      //   rules: [/\/hauptnavigation\/presse\//],
      //   attrs: {
      //     title: 'Presse',
      //     look: 'secondary-2',
      //   },
      // },
    ],
    nachKon: [
      {
        rules: [/\/was-uns-bewegt\//],
        attrs: {
          title: 'Magazin',
          look: 'secondary-1',
        },
      },
      {
        rules: [/\/what-drives-us\//],
        attrs: {
          title: 'Magazine',
          look: 'secondary-1',
        },
      },
      {
        rules: [/\/nachhaltigkeit\//],
        attrs: {
          title: 'Nachhaltigkeit',
          look: 'secondary-2',
        },
      },
      {
        rules: [/\/sustainability\//],
        attrs: {
          title: 'Sustainability',
          look: 'secondary-2',
        },
      },
      {
        rules: [/\/newsroom\//],
        attrs: {
          title: 'News', //same de and en
          look: 'secondary-3',
        },
      },
      {
        rules: [/\/kursinformationen\//],
        attrs: {
          title: 'Devisenkurse & Edelmetalle',
          look: 'secondary-4',
        },
      },
      {
        rules: [/\/price-information\//],
        attrs: {
          title: 'FX Rates & Precious metals',
          look: 'secondary-4',
        },
      },
      {
        rules: [/\/karriere\//],
        attrs: {
          title: 'Karriere',
          look: 'secondary-5',
        },
      },
      {
        rules: [/\/careers\//],
        attrs: {
          title: 'Careeers',
          look: 'secondary-5',
        },
      },
      {
        rules: [/\/research\//],
        attrs: {
          title: 'Research', //same de and en
          look: 'secondary-6',
        },
      },
    ],
  };

  return rules[portal] ?? [];
}

export function getBadgeAttributes(
  href: string,
  portal: 'puk' | 'nachKon',
  language: AppSettings['site']['language'],
): BannerTextBadgeProps {
  const badgeRules = getBadgeRules(portal, language);
  const defaultAttributes: BannerTextBadgeProps = {
    title: language === 'de' ? 'Wissenwertes' : 'Knowledge',
    look: 'supplementary',
  };

  return (
    badgeRules.find((item) => item.rules.find((rule) => href.match(rule)))
      ?.attrs ?? defaultAttributes
  );
}
