import { styled } from '@neui/core';
import { VStack } from '@neui/layout';
import { Typography } from '@neui/styleguide-commerzbank';

import { ClickItem } from '@components/neui-components/atoms/ClickItem';
import { MostReadArticleType } from 'pages';
import { MarginTopTypography } from '@components/CdsStyledComponents';
import { useTranslation } from '@utils/i18n';

const StyledVStack = styled(VStack, {
  zIndex: 1,
  marginY: 12,
});

export type TopArticlesProps = {
  articles: MostReadArticleType[];
};
export function TopArticles({ articles }: TopArticlesProps) {
  const { $t } = useTranslation();

  return (
    <>
      <MarginTopTypography size={7} weight={'book'} color={'helper'}>
        {$t('MOST_READ_ARTICLES')}
      </MarginTopTypography>

      {articles !== undefined && (
        <VStack>
          {articles.map((article, index: number) => {
            return (
              <ClickItem key={article.title + index} href={article.slug}>
                <StyledVStack css={{ zIndex: 1, marginY: 12 }}>
                  <Typography size={6} weight="medium">
                    {article.title}
                  </Typography>
                  <Typography size={7} weight={'book'} color={'helper'}>
                    {article.excerpt}
                  </Typography>
                </StyledVStack>
              </ClickItem>
            );
          })}
        </VStack>
      )}
    </>
  );
}
