import { interaction___close } from '@neui/styleguide-commerzbank';

import { WarningMessage } from './WarningMessage';

export function ConnectionLost() {
  return (
    <WarningMessage
      size="small"
      variant="solid"
      look={'error'}
      icon={interaction___close}
      message="Verbindung unterbrochen. Wir informieren Sie, wenn Ihre Verbindung wieder hergestellt ist."
    />
  );
}
