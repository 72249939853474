import { AsProps, CSSProps, styled } from '@neui/core';
import { Box } from '@neui/layout';
import { forwardRef, HTMLAttributes } from 'react';

export type HeaderContainerProps = HTMLAttributes<HTMLDivElement> &
  AsProps &
  CSSProps & {};

const Container = styled(Box, {
  boxShadow: '0px 2px 4px -1px rgba(0, 0, 0, 0.36)',
  backgroundColor: '$colors$secondary !important',
  paddingY: 16,
  paddingX: 28,
  '&:focus, &:focus-visible': {
    outline: 'none',
  },
  '@sm': {
    paddingY: 24,
    paddingX: 32,
  },
});

export const HeaderContainer = forwardRef<HTMLDivElement, HeaderContainerProps>(
  ({ children, ...rest }, forwardedRef) => {
    return (
      <Container ref={forwardedRef} {...rest} as={'header'}>
        {children}
      </Container>
    );
  },
);
HeaderContainer.displayName = 'HeaderContainer';
