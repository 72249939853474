import { styled } from '@neui/core';

export const MinHeightLayout = styled('div', {
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100vh',
});

export const GrowingMain = styled('main', {
  flexGrow: 1,
  overflow: 'hidden',
});

export const IconLinkWrapper = styled('li', {
  listStyle: 'none',
  display: 'flex',
  justifyContent: 'center',
  maxWidth: 24,
  maxHeight: 24,
  '@md': {
    maxWidth: 'none',
    maxHeight: 'none',
  },

  ['&:empty']: {
    display: 'none',
  },
});

export const NavWrapper = styled('ul', {
  display: 'flex',
  flexDirection: 'row',
  gap: 24,
  '@md': {
    gap: 32,
  },
});
