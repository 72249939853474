import { VStack } from '@neui/layout';
import { Typography } from '@neui/styleguide-commerzbank';

import { Icon } from '@components/neui-components/atoms/Icon';
import { ThumbnailWrapper } from '@components/neui-components/molecules/CircleIconLink';
import { ClickItem } from '@components/neui-components/atoms/ClickItem';
import { useTranslation } from '@utils/i18n';
import {
  alternativePortalsEnglish,
  alternativePortalsGerman,
} from '@static-content/alternativePortals';

import { StyledTypography } from './CdsSearchResultsWrapper';

export function TopicPageList() {
  const { $t, language } = useTranslation();

  const alternativePortals =
    language === 'de' ? alternativePortalsGerman : alternativePortalsEnglish;

  return (
    <VStack
      marginTop={{ base: '$layout-2', md: '$layout-3' }}
      marginBottom={{ base: '$layout-2', md: '$layout-3' }}
    >
      <StyledTypography
        size={5}
        weight="medium"
        css={{ paddingBottom: '$component-4' }}
      >
        {$t('SEARCH_GO_TO_PORTAL')}
      </StyledTypography>

      {alternativePortals.map((item, idx) => {
        return (
          <ClickItem key={idx} href={item.url}>
            <VStack
              flexDirection={'row'}
              alignItems={'center'}
              spacing={'$component-4'}
            >
              <ThumbnailWrapper
                alignItems="center"
                justifyContent="center"
                look={'secondary'}
              >
                <Icon icon={item.icon} variant={'outline'} size="small" />
              </ThumbnailWrapper>
              <Typography size={6} weight="medium">
                {item.text}
              </Typography>
            </VStack>
          </ClickItem>
        );
      })}
    </VStack>
  );
}
