"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getVimeoId = getVimeoId;
function getVimeoId(url) {
    const possibleVimeoUrls = [
        'https://player.vimeo.com/video/',
        'https://vimeo.com/video/',
        'https://player.vimeo.com/',
        'https://vimeo.com/',
    ];
    let videoId;
    for (let i = 0; i < possibleVimeoUrls.length; i++) {
        const currentVimeoUrl = possibleVimeoUrls[i];
        if (url.startsWith(currentVimeoUrl)) {
            videoId = parseInt(url.slice(currentVimeoUrl.length));
            break;
        }
    }
    if (videoId) {
        return videoId;
    }
    else {
        throw Error(`${url} is not a valid vimeo link`);
    }
}
