import { CSSProps, ResponsiveValue, AsProps, styled } from '@neui/core';
import { HStack } from '@neui/layout';
import { HTMLAttributes, PropsWithChildren } from 'react';

type XColumnsGridItemProps = HTMLAttributes<HTMLDivElement> &
  CSSProps &
  AsProps &
  PropsWithChildren<{
    columns: ResponsiveValue<number>;
    maxColumns?: number;
  }>;

export const XColumnsGridItem = ({
  maxColumns = 12,
  columns,
  children,
  renderAs,
  css,
  ...rest
}: XColumnsGridItemProps) => {
  function getStyles(columns: ResponsiveValue<number>) {
    if (typeof columns === 'number') {
      return { width: `${(columns / maxColumns) * 100}%` };
    }
    let css = {};
    for (const k in columns) {
      const key = `@${k}`;
      const value = {
        width: `${((columns[k] as number) / maxColumns) * 100}%`,
      };
      if (k === 'base') {
        Object.assign(css, value);
      } else {
        Object.assign(css, { [key]: value });
      }
    }
    return css;
  }

  const cssStyles =
    typeof columns === 'number'
      ? { width: `${(columns / maxColumns) * 100}%` }
      : getStyles(columns);
  return (
    <Wrapper {...rest} css={{ ...css, ...cssStyles }} as={renderAs}>
      {children}
    </Wrapper>
  );
};

const Wrapper = styled(HStack, {});
