// got this from Dan Abramov. React is weird.
// https://overreacted.io/making-setinterval-declarative-with-react-hooks/

import { useEffect, useRef } from 'react';

export function useInterval(
  callback: (...args: any) => void,
  delay: number | null
) {
  const setMsgsCallback = useRef<typeof callback>(() => {});
  useEffect(() => {
    setMsgsCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    if (delay !== null) {
      let id = setInterval(async () => {
        setMsgsCallback?.current();
      }, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}
