import React from 'react';
import { styled } from '@neui/core';
import { Box } from '@neui/layout';

import { IconLink, Wrapper } from '@components/neui-components/atoms/IconLink';
import { IconProps } from '@components/neui-components/atoms/Icon';

export type NavItemProps = {
  label: string;
  icon: IconProps['icon'] | JSX.Element;
  onClick?: (e?: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  href?: string;
  isLanguageSwitch?: boolean;
  isSvg?: boolean;
};

export const NavLink = ({
  label,
  icon,
  onClick = () => {},
  href,
  isLanguageSwitch,
  isSvg,
  ...rest
}: NavItemProps) => {
  const iconType = (icon: any): icon is IconProps['icon'] => {
    return typeof icon === 'function';
  };

  return (
    <StyledIconLink
      {...rest}
      icon={iconType(icon) ? icon : () => undefined}
      href={href}
      animationDirection="top"
      iconPosition="left"
      onClick={onClick}
      languageSwitch={isLanguageSwitch}
      isSvg={isSvg}
    >
      {!iconType(icon) && React.isValidElement(icon) && icon}
      <LabelContainer languageSwitch={isLanguageSwitch}>{label}</LabelContainer>
    </StyledIconLink>
  );
};

const LabelContainer = styled(Box, {
  display: 'none',
  fontSize: '$desktop-m1-6',
  variants: {
    languageSwitch: {
      true: {
        display: 'block',
      },
    },
  },
  '@md': {
    display: 'block',
  },
});

const StyledIconLink = styled(IconLink, {
  [`& ${Wrapper}`]: {
    gap: 0,
    span: {
      display: 'flex',
    },
  },
  variants: {
    languageSwitch: {
      true: {
        [`& ${Wrapper} > :nth-child(2)`]: {
          display: 'none',
        },
        '@md': {
          [`& ${Wrapper} > :nth-child(2)`]: {
            display: 'block',
          },
        },
      },
    },
    isSvg: {
      true: {
        [`& ${Wrapper} `]: {
          gap: '0 !important',
        },
        [`& ${Wrapper} > div`]: {
          minWidth: 0,
          width: 0,
        },
      },
    },
  },
  '@md': {
    [`& ${Wrapper}`]: {
      gap: '$component-3',
      span: {
        gap: '$component-3',
      },
    },
  },
});
