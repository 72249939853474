import { AsProps, CSSProps, styled } from '@neui/core';
import { Flex } from '@neui/layout';
import { forwardRef, HTMLAttributes, PropsWithChildren } from 'react';

export type BannerContainerProps = AsProps &
  CSSProps &
  HTMLAttributes<HTMLDivElement> &
  PropsWithChildren<{}>;

const Container = styled(Flex, {
  padding: '$component-4',
  backgroundColor: '$colors$neutral100',
  borderRadius: '$space$component-4',
});

export const BannerContainer = forwardRef<HTMLDivElement, BannerContainerProps>(
  ({ ...rest }, forwardedRef) => {
    return <Container ref={forwardedRef} flexWrap="wrap" {...rest} />;
  },
);
BannerContainer.displayName = 'BannerContainer';
