import { WarningMessage } from './WarningMessage';

export function MessageNotSent() {
  return (
    <WarningMessage
      size="small"
      look={'alert'}
      customIcon={
        <svg
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5 2V6H7V2V1H5V2Z"
            fill="#002E3C"
          />
          <circle cx="6" cy="9.5" r="1.5" fill="#002E3C" />
        </svg>
      }
      message="Nachricht nicht gesendet. Bitte aktualisieren Sie Ihre Nachricht."
    />
  );
}
