import { Typography } from '@neui/styleguide-commerzbank';

import { WarningScreen } from './WarningScreen';

export function BrokenChatScreen() {
  return (
    <WarningScreen
      imgSrc="/errorIcon.svg"
      imgAlt="Digitaler Assistent ist nicht verfügbar"
      headline="Der Chat wurde beendet"
    >
      <Typography size={6} textAlign={'center'}>
        Die Verbindung wurde leider unterbrochen. Bitte melden Sie sich erneut
        im Online-Banking an und starten Sie den Chat erneut.
      </Typography>
    </WarningScreen>
  );
}
