"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.queryMoreLikeThisApiWith = exports.queryResultsApi = exports.querySuggestionsApi = void 0;
const utils_1 = require("@cds/utils");
const querySuggestionsApi = (_a) => __awaiter(void 0, [_a], void 0, function* ({ url, signal, query, }) {
    return (0, utils_1.fetchUtil)(url, undefined, {
        suggest: {
            query,
            limit: 5,
        },
    }, 'POST', { signal });
});
exports.querySuggestionsApi = querySuggestionsApi;
const queryResultsApi = (_a) => __awaiter(void 0, [_a], void 0, function* ({ url, signal, query, limit, offset, fuzzy, }) {
    const filters = [];
    return (0, utils_1.fetchUtil)(url, undefined, {
        search: {
            query,
            limit: limit !== null && limit !== void 0 ? limit : 10,
            offset,
            fuzzy,
            filters,
            includeProperties: ['semanticDocumentInfo'],
        },
        // one suggestion is used for "maybe you meant..."
        suggest: {
            query,
            limit: 1,
        },
    }, 'POST', { signal });
});
exports.queryResultsApi = queryResultsApi;
const queryMoreLikeThisApiWith = (_a) => __awaiter(void 0, [_a], void 0, function* ({ url, moreLikeThisUrl, limit, }) {
    return (0, utils_1.fetchUtil)(url, undefined, {
        moreLikeThis: {
            like: {
                url: moreLikeThisUrl,
            },
            limit,
        },
    }, 'POST');
});
exports.queryMoreLikeThisApiWith = queryMoreLikeThisApiWith;
