import { AsProps, CSSProps } from '@neui/core';
import { forwardRef } from 'react';

import {
  BannerContainer,
  BannerContainerProps,
} from '../atoms/BannerContainer';
import { BannerContent, BannerContentProps } from '../atoms/BannerContent';

export type BannerProps = AsProps &
  CSSProps &
  BannerContainerProps &
  BannerContentProps & {};

export const Banner = forwardRef<HTMLDivElement, BannerProps>(
  (
    {
      icon,
      badgeContent,
      variant,
      look,
      size,
      children,
      actions,
      customIcon,
      ...rest
    },
    forwardedRef,
  ) => {
    return (
      <BannerContainer ref={forwardedRef} {...rest}>
        <BannerContent
          icon={icon}
          badgeContent={badgeContent}
          variant={variant}
          look={look}
          size={size}
          actions={actions}
          customIcon={customIcon}
        >
          {children}
        </BannerContent>
      </BannerContainer>
    );
  },
);
Banner.displayName = 'Banner';
