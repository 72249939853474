import { useRuntimeSettings } from '@utils/config';

import de from './de.json';
import en from './en.json';

export function useTranslation() {
  const {
    site: { language },
  } = useRuntimeSettings();

  const json = language === 'de' ? de : en;

  const $t = (slug: keyof typeof json): string => json[slug];

  return { $t, language };
}
