import { styled } from '@neui/core';
import { Typography } from '@neui/styleguide-commerzbank';
import { Box, VStack } from '@neui/layout';

import { IconLink } from './neui-components/atoms/IconLink';
import { Banner } from './neui-components/molecules/Banner';

export const SearchIconPositioner = styled(IconLink, {
  zIndex: 3,
  display: 'block',
  position: 'absolute',
  cursor: 'unset !important',
});

export const StyledRemoveIcon = styled(IconLink, {
  zIndex: 3,
  cursor: 'pointer',
  display: 'block',
  transform: 'translate(-100%)',
});

export const CdsAutosuggestContainer = styled(Box, {
  position: 'relative',
  zIndex: 3,
  '& #react-autowhatever-1': {
    flexDirection: 'row',
    justifyContent: 'center',
  },
  '& .react-autosuggest__section-title': {
    marginLeft: 48,
    marginBottom: 16,
  },
  '& .react-autosuggest__section-container--first > .react-autosuggest__suggestions-list':
    {
      marginLeft: 32,
    },
  '& ul.react-autosuggest__suggestions-list': {
    cursor: 'pointer',
    listStyle: 'none !important',
  },
  '& .react-autosuggest__suggestions-container--open': {
    display: 'block',
    position: 'relative',
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
    zIndex: 2,
  },
});

export const ParagraphMaxWidth = styled(Typography, {
  maxWidth: 720,
});

export const CdsSearchInputField = styled('input', {
  flexShrink: 0,
  border: 0,
  width: '100%',
  minHeight: 28,
  borderBottom: 'solid 1px #5b7a82',
  textIndent: 48,
  fontFamily: 'Gotham, sans-serif',
  fontSize: 17,
  background: '$color$secondary',
  flexGrow: 1,
  zIndex: 2,
  color: '$text-standard',
  fontWeight: 300,
  transition: 'border 200ms ease-in-out',
  '&:focus': {
    outline: 'none',
  },
});

export const CdsSearchContainer = styled(Box, {
  position: 'fixed',
  top: 0,
  width: '100%',
  height: 'min-content',
  zIndex: 2,
});

export const CdsSearchOverlay = styled(Box, {
  position: 'absolute',
  top: 0,
  width: '100%',
  minHeight: '100vh',
  transition: 'opacity 450ms',
  transitionTimingFunction: 'cubic-bezier(0.1, 0, 0, 1)',
  variants: {
    visible: {
      false: {
        display: 'none',
      },
      true: {},
    },
    opaque: {
      true: {
        background: '#002E3C',
      },
      false: {
        background: 'rgba(0, 37, 48, 0.75)',
      },
    },
  },
});

export const SearchWrapper = styled(VStack, {
  backgroundColor: '$background',
  paddingX: 28,
  '@sm': {
    paddingX: 86,
  },
  '@md': {
    paddingX: '116px',
  },
});

export const SearchContent = styled(VStack, {
  width: '100%',
  '@sm': {
    maxWidth: 768,
  },
  '@lg': {
    maxWidth: 1024,
  },
});

export const StyledLi = styled('li', {
  listStyle: 'none',
});

export const StyledUl = styled('ul', {
  display: 'flex',
  gap: '$component-2',
  flexWrap: 'wrap',
  columnGap: 8,
  rowGap: 8,
  '@md': {
    columnGap: 12,
    rowGap: 8,
  },
});

export const StyledBanner = styled(Banner, {
  wordBreak: 'break-word',
});

export const StyledBox = styled(Box, {
  alignSelf: 'flex-start',
  marginBottom: '24px',
  '@md': {
    alignSelf: 'center',
    marginBottom: 'none',
  },
});

export const ChipsReducer = styled(Box, {
  padding: '2px 1px 2px 2px',
});

export const NoResultSpacing = styled(VStack, {
  width: '100%',
  variants: {
    areThereResults: {
      true: {
        gap: '$layout-3',
        '@md': {
          gap: '$layout-5',
        },
      },
    },
  },
});

export const MarginTopTypography = styled(Typography, {
  marginTop: '$component-7',
  '@md': {
    marginTop: '$layout-5',
  },
});
