import { PropsWithChildren, useMemo, useRef, useState } from 'react';
import { useEffect } from 'react';
import {
  globalStyles,
  interaction___search,
} from '@neui/styleguide-commerzbank';
import { styled } from '@neui/core';
import { useRuntimeSettings } from '@utils/config';
import { createPortalEntity, useTracker } from '@utils/snowplowTracking';
import { SeoData } from '@components/SEO/SeoData';
import { MobileChecker } from '@utils/MobileChecker';
import { Header } from '@components/neui-components/atoms/Header';
import { Footer } from '@components/neui-components/atoms/Footer';
import { Section } from '@components/neui-components/atoms/Section';
import { baseTheme } from 'styling/stitches.config';
import { Chat } from '@components/Chat/Chat';
import {
  useChatStore,
  selectChatProps,
  selectChatActions,
} from '@components/Chat/store/store';
import { makeStagedLinkFunction } from '@utils/helpers/linkHelpers';
import { useMakeLink } from '@components/Link';
import { useTranslation } from '@utils/i18n';
import { useApi } from '@components/Chat/ChatWindow';
import { CdsSearchAndResults } from '@components/Search/CdsSearchAndResults';
import { LanguageSwitch } from '@components/LanguageSwitch';
import { GA4TrackNavigationClick, GA4TrackPageView } from '@utils/tracking';
import { maskIfNecessary, stripQueryParams } from '@components/Search/helpers';

import { NavLink } from '../NavItems/CdsNavItems';
import { OnlineBankingIcon, useSearchRef } from './Layout.helpers';
import {
  GrowingMain,
  IconLinkWrapper,
  MinHeightLayout,
  NavWrapper,
} from './Layout.styles';

import type { LayoutProps } from './types';

export default function CdsLayout({
  children,
  title,
  translateTitle,
  slug,
  excerpt,
  pageType,
  pageTemplate,
}: PropsWithChildren<LayoutProps>) {
  globalStyles();
  globalStyles();

  const apiClient = useApi();
  const {
    site: { language },
    chat: { enable: isChatEnabled },
    tracking: {
      ga4: { enabled: enableGA4Tracking },
    },
    staticContent: { mostReadArticles, googleSiteVerificationTag },
    ...config
  } = useRuntimeSettings();

  const isOBPage = pageType === 'Landing Page';
  const isSearchPage = pageType === 'Search Page';
  const isIndexPage = pageType === 'Homepage';

  const { $t } = useTranslation();
  const makeLink = useMakeLink();
  const { openSearch, setOpenSearch, setCloseSearch } =
    useSearchRef(isSearchPage);

  const documentTitle = translateTitle ? $t(title as any) : title;

  const home = $t('HOME');
  const portalContext = useMemo(
    () => createPortalEntity(home, documentTitle),
    [home, documentTitle],
  );
  const makeStagedLink = makeStagedLinkFunction(config.build.stage);

  const is404 = slug.includes('404');
  const { isChatOpen } = useChatStore(selectChatProps);
  const { setUser, setChatState } = useChatStore(selectChatActions);

  const {
    trackPageView,
    enableLinkClickTracking,
    trackButtonClick,
    trackLinkClick,
  } = useTracker(CdsLayout.name);

  const [hasSearchResults, setHasSearchResults] = useState(false);

  const seoTitle = isIndexPage
    ? is404
      ? documentTitle
      : $t('SEO_TITLE_APPENDIX')
    : documentTitle + ' - ' + $t('SEO_TITLE_APPENDIX');

  useEffect(() => {
    // SPAs have a problem of not resetting the focus on page change so
    // we gotta do this manually
    const header = document.getElementsByTagName('header');
    !isOBPage && header[0].focus();
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [slug]);

  useEffect(() => {
    async function initiateChatBot() {
      if (!isChatEnabled) {
        console.log('chat is disabled');
        return;
      }

      try {
        const res = await apiClient.chat.initiateChatUsingPost(
          {
            PlaceWhereCallWasInitiated: '91101',
            Language: 'L01', // Deutsch
            'CDS-AppVersion': '0.0.1',
          },
          {
            headers: {
              'Content-Type': 'application/json',
              pToken: window?.ccb_cif?.pToken ?? '',
            },
          },
        );

        const data = res.data.result?.data;
        setChatState({
          workgroupName: data?.mobileAppAvailability?.workgroupName,
        });
      } catch (e) {
        console.log('Unable to initiate chat');
      }
    }

    initiateChatBot();
  }, [isChatEnabled, setChatState]);

  useEffect(() => {
    window.addEventListener('offline', () => {
      setUser({ status: 'offline' });
      // this is weird but works
      window.addEventListener('online', () => {
        setUser({ status: 'reconnected' });
      });
    });
  }, []);

  const SearchButton = (
    <NavLink
      label={$t('SEARCH')}
      icon={interaction___search}
      onClick={() => {
        enableGA4Tracking &&
          GA4TrackNavigationClick(
            'mainNavigationClick',
            'quick_access',
            'Search',
            undefined,
          );
        trackButtonClick?.('header search icon', 'navigation', [portalContext]);
        openSearch.current();
      }}
      data-cy="search-icon"
    />
  );

  const obUrl = makeStagedLink(
    'https://kunden.commerzbank.de/banking/landingpage',
  );

  const OnlineBankingButton = (
    <NavLink
      label={$t('ONLINE_BANKING')}
      icon={<OnlineBankingIcon />}
      href={obUrl}
      onClick={() => {
        enableGA4Tracking &&
          GA4TrackNavigationClick(
            'mainNavigationClick',
            'quick_access',
            $t('ONLINE_BANKING'),
            obUrl,
          );
        trackLinkClick?.(obUrl, $t('ONLINE_BANKING'));
      }}
      isSvg={true}
    />
  );

  const isSearchVisible = isIndexPage || isSearchPage;
  const metaRobotsTag = isSearchPage ? 'noindex, nofollow' : 'index, follow';

  const mobileChecker = new MobileChecker();
  const mobileOs = mobileChecker.deviceType;

  const navItems: JSX.Element[] = [
    SearchButton,
    <LanguageSwitch key="navitem-2" />,
    OnlineBankingButton,
  ];

  const inputRef = useRef<HTMLInputElement>(null);
  const previousPageRefGA4 = useRef<string | null>(null);
  const previousPageRefSnowplow = useRef<string | null>(null);

  useEffect(() => {
    enableLinkClickTracking?.({
      pseudoClicks: true,
      options: {
        denylist: isIndexPage
          ? ['c-bjBqHr-daxzDk-look-secondary', 'c-cIdiJW']
          : [],
      },
      context: [portalContext],
    });
  }, [enableLinkClickTracking, isIndexPage, portalContext]);

  useEffect(() => {
    const pageUrl = maskIfNecessary(window.location.href);

    const normalizedPageUrl = stripQueryParams(pageUrl);
    const normalizedPreviousUrl = stripQueryParams(
      previousPageRefGA4.current || '',
    );
    // Track 'Result Page' only if it's the first page load, not during navigation.
    const isFirstPageView = !previousPageRefGA4.current;
    if (
      enableGA4Tracking &&
      (isFirstPageView || normalizedPageUrl !== normalizedPreviousUrl)
    ) {
      const isResultPage = /\?q=/.test(pageUrl);
      const GA4PageType = isResultPage ? 'Result Page' : pageType;
      const GA4PageTemplate = isResultPage ? undefined : pageTemplate;
      GA4TrackPageView(
        pageUrl,
        seoTitle,
        GA4PageType,
        GA4PageTemplate,
        previousPageRefGA4.current || document.referrer,
        language,
      );
      previousPageRefGA4.current = pageUrl;
    }
  }, [slug, seoTitle, enableGA4Tracking, pageType, pageTemplate, language]);

  useEffect(() => {
    // hack: since snowplow tracking sometimes seems to pick up the old title, postpone tracking the page view into the next animation frame
    window.requestAnimationFrame(() => {
      const pageUrl = maskIfNecessary(window.location.href);

      const normalizedPageUrl = stripQueryParams(pageUrl);
      const normalizedPreviousUrl = stripQueryParams(
        previousPageRefSnowplow.current || '',
      );
      const isFirstPageView = !previousPageRefSnowplow.current;
      if (
        trackPageView !== undefined &&
        (isFirstPageView || normalizedPageUrl !== normalizedPreviousUrl)
      ) {
        trackPageView({ context: [portalContext] });
        previousPageRefSnowplow.current = pageUrl;
      }
    });
  }, [slug, trackPageView, portalContext, language]);

  return (
    <MinHeightLayout className={baseTheme}>
      <SeoData
        title={seoTitle}
        description={excerpt ?? undefined}
        absoluteUrl={makeLink({ href: slug, absoluteUrl: true }) ?? ''}
        slug={slug}
        metaRobotsTag={metaRobotsTag}
        googleSiteVerificationTag={googleSiteVerificationTag}
        isSearchVisible={isSearchVisible}
        isIndexPage={isIndexPage || isSearchPage}
        imageSrc={makeLink({
          href: '/commerzbank-logo.png',
          alwaysPrependBasePath: true,
          absoluteUrl: true,
        })}
      />
      {!isOBPage && (
        <Header
          isSearchActive={false}
          title={$t('HEADER_TITLE')}
          navItems={
            <nav>
              <NavWrapper>
                {navItems.map((item, idx) => (
                  <IconLinkWrapper key={idx}>{item}</IconLinkWrapper>
                ))}
              </NavWrapper>
            </nav>
          }
        />
      )}
      {isChatEnabled && isChatOpen && <Chat />}
      <CdsSearchAndResults
        isSearchPage={isSearchPage}
        searchApiUrl={config.search.apiUrl}
        mostSearchedTerms={config.search.topSearchTerms}
        pageType={pageType}
        inputRef={inputRef}
        mobileOs={mobileOs}
        mostReadArticles={mostReadArticles}
        setOpenSearch={setOpenSearch}
        setCloseSearch={setCloseSearch}
        onHasResults={setHasSearchResults}
        referringPortal={config.search.portalUrl}
      />
      <GrowingMain>
        {!hasSearchResults && (
          <>
            {children}
            <StyledSection />
          </>
        )}
      </GrowingMain>
      {!isOBPage && <Footer />}
    </MinHeightLayout>
  );
}

const StyledSection = styled(Section, {
  paddingTop: '0 !important',
});
