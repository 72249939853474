import { AsProps, CSSProps, styled } from '@neui/core';
import { HStack } from '@neui/layout';
import { Typography } from '@neui/styleguide-commerzbank';
import { forwardRef, HTMLAttributes } from 'react';

import { Icon, IconProps } from './Icon';

const ChipIcon = styled(Icon, {
  marginY: -5,
});

const Container = styled('button', {
  all: 'unset',
  display: 'inline-block',
  padding: '$component-2 $component-3',
  borderRadius: 30,
  transition: 'all 100ms ease',
  '&:not([data-disabled="true"])': {
    '&:hover, :focus': {
      cursor: 'pointer',
    },
  },
  '&[data-disabled="true"]': {
    pointerEvents: 'none',
    [`& ${Typography}`]: {
      color: '$text-disabled',
    },
  },
  variants: {
    active: {
      true: {},
      false: {},
    },
    noBorder: {
      false: {
        boxShadow: '0px 0px 0px 1px $colors$text-helper',
        '&:not([data-disabled="true"])': {
          '&:hover': {
            boxShadow: '0px 0px 0px 2px $colors$text-helper',
          },
          '&:focus-visible': {
            outline: '2px solid $text-standard',
            outlineOffset: 4,
          },
        },
        '&[data-state="checked"]': {
          backgroundColor: '$background-hover',
        },
        '&[data-disabled="true"]': {
          boxShadow: '0px 0px 0px 1px $colors$text-disabled',
        },
      },
      true: {},
    },
  },
  compoundVariants: [
    {
      active: true,
      noBorder: false,
      css: {
        backgroundColor: '$background-hover',
      },
    },
  ],
});

export type ChipProps = AsProps &
  CSSProps &
  HTMLAttributes<HTMLButtonElement> &
  Omit<IconProps, 'icon'> &
  Partial<Pick<IconProps, 'icon'>> & {
    active?: boolean;
    disabled?: boolean;
    noBorder?: boolean;
    iconPosition?: 'left' | 'right';
  };

export const Chip = forwardRef<HTMLButtonElement, ChipProps>(
  (
    {
      children,
      active = false,
      disabled = false,
      noBorder = false,
      icon,
      variant,
      iconPosition = 'left',
      ...rest
    },
    forwardedRef,
  ) => {
    return (
      <Container
        {...rest}
        ref={forwardedRef}
        active={active}
        data-disabled={disabled}
        noBorder={noBorder}
      >
        <HStack
          alignItems="center"
          justifyContent="center"
          spacing="$component-2"
        >
          {icon && iconPosition === 'left' && (
            <ChipIcon icon={icon} variant={variant} size="small" />
          )}
          <Typography size={7} weight="medium">
            {children}
          </Typography>
          {icon && iconPosition === 'right' && (
            <Icon icon={icon} variant={variant} size="small" />
          )}
        </HStack>
      </Container>
    );
  },
);
Chip.displayName = 'Chip';
