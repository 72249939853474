import { interaction___checkmark } from '@neui/styleguide-commerzbank';
import { keyframes, styled } from '@neui/core';
import { baseTheme, oceanElevatedTheme } from 'styling/stitches.config';

import { WarningMessage } from './WarningMessage';

export function ConnectionRecovered() {
  return (
    <StyledWarningMessage
      className={`${baseTheme} ${oceanElevatedTheme}`}
      size="small"
      variant="solid"
      look={'success'}
      icon={interaction___checkmark}
      message="Ihre Verbindung ist wiederhergestellt."
    />
  );
}

const disappear = keyframes({
  '0%': { opacity: 1, display: 'block' },
  '99%': { opacity: 0 },
  '100%': { display: 'none', opacity: 0 },
});

const StyledWarningMessage = styled(WarningMessage, {
  animation: `${disappear} 0.5s forwards`,
  animationDelay: '1.5s',
});
