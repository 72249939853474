import { useEffect } from 'react';

// Updates the height of a <textarea> when the value changes.
export const useAutosizeTextArea = (
  textAreaRef: HTMLTextAreaElement | null,
  value: string,
) => {
  useEffect(() => {
    if (textAreaRef) {
      // reset the height momentarily to get the correct scrollHeight for the textarea
      textAreaRef.style.height = '0px';
      const MAX_HEIGHT = 128;
      const scrollHeight = textAreaRef.scrollHeight;

      if (scrollHeight <= MAX_HEIGHT) {
        textAreaRef.style.height = scrollHeight + 'px';
      } else {
        textAreaRef.style.height = MAX_HEIGHT + 'px';
      }
    }
  }, [textAreaRef, value]);
};
