export default function ChainIcon() {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.8192 1.17731C17.5659 1.30177 19.0677 2.46148 19.6298 4.11997C20.0123 5.23246 19.9257 6.45268 19.3898 7.49997L17.8898 10.5L16.1098 9.59997L17.6098 6.59997C17.8883 6.04353 17.9315 5.39859 17.7298 4.80997C17.4327 3.90266 16.6162 3.26528 15.664 3.19726C14.7117 3.12924 13.8129 3.6441 13.3898 4.49997L10.3898 10.5C10.1316 11.0449 10.0958 11.669 10.2898 12.24C10.4847 12.8357 10.9095 13.3289 11.4698 13.61L10.5698 15.39C9.53596 14.8751 8.74996 13.9693 8.38584 12.8732C8.02173 11.7772 8.10954 10.5811 8.62984 9.54997L11.6298 3.54997C12.4214 1.98793 14.0725 1.05286 15.8192 1.17731ZM13.7298 12.76C13.535 12.1642 13.1101 11.671 12.5498 11.39L13.4498 9.60997V9.65997C14.4837 10.1748 15.2697 11.0806 15.6338 12.1767C15.998 13.2728 15.9101 14.4688 15.3898 15.5L12.3898 21.5C11.6502 22.9749 10.1398 23.9043 8.48984 23.9C7.81585 23.9026 7.15089 23.745 6.54984 23.44C5.51596 22.9251 4.72996 22.0193 4.36584 20.9232C4.00173 19.8272 4.08954 18.6311 4.60984 17.6L6.10984 14.6L7.88984 15.5L6.38984 18.5C6.12505 19.0423 6.08201 19.6664 6.26984 20.24C6.56695 21.1473 7.38344 21.7847 8.33572 21.8527C9.288 21.9207 10.1868 21.4058 10.6098 20.55L13.6098 14.55C13.8883 13.9935 13.9315 13.3486 13.7298 12.76Z"
        fill="#002E3C"
      />
    </svg>
  );
}
