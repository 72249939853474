import { ConnectionLost } from './WarningMessages/ConnectionLost';
import { ConnectionRecovered } from './WarningMessages/ConnectionRecovered';
import { MessageNotSent } from './WarningMessages/MessageNotSent';
import { selectChatProps, useChatStore } from './store/store';

export function WarningRenderer() {
  const { userState, unsentMsgs } = useChatStore(selectChatProps);
  const hasUnsentMsgs = unsentMsgs.length > 0;
  if (userState.status === 'offline') {
    return <ConnectionLost />;
  }
  if (userState.status === 'reconnected') {
    return <ConnectionRecovered />;
  }
  if (hasUnsentMsgs) {
    return <MessageNotSent />;
  }
  return <></>;
}
