import { forwardRef, ReactNode } from 'react';
import {
  Provider,
  Root,
  Title,
  Description,
  Action,
  Viewport,
  Close,
} from '@radix-ui/react-toast';
import { AsProps, CSSProps, keyframes, styled, VariantProps } from '@neui/core';
import { VStack } from '@neui/layout';

import { BannerContainer } from '../atoms/BannerContainer';
import { BannerContent } from '../atoms/BannerContent';

import type { PortalProps } from '@radix-ui/react-portal';
import type { BannerProps } from './Banner';
import type {
  ToastProps,
  ToastProviderProps,
  ToastActionProps,
} from '@radix-ui/react-toast';

export type SnackbarToastActionProps = ToastActionProps & {
  content: ReactNode;
};

export type SnackbarToastProps = AsProps &
  CSSProps &
  ToastProviderProps &
  ToastProps &
  BannerProps &
  PortalProps & {
    trigger?: ReactNode;
    actions?: SnackbarToastActionProps | SnackbarToastActionProps[];
    toastTitle?: ReactNode;
    closeAction?: ReactNode;
  } & VariantProps<typeof StyledRoot>;

const VIEWPORT_PADDING = 25;

const hide = keyframes({
  '0%': { opacity: 1 },
  '100%': { opacity: 0 },
});

const slideIn = keyframes({
  from: { transform: `translateY(calc(100% + ${VIEWPORT_PADDING}px))` },
  to: { transform: 'translateY(0)' },
});

const swipeOut = keyframes({
  from: { transform: 'translateY(var(--radix-toast-swipe-end-y))' },
  to: { transform: `translateY(calc(100% + ${VIEWPORT_PADDING}px))` },
});

const StyledProvider = styled(Provider, {});
const StyledRoot = styled(Root, {
  variants: {
    position: {
      center: {
        position: 'fixed',
        margin: 'auto',
        left: 0,
        right: 0,
        bottom: '25px',
        width: '90%',
        '@md': {
          width: '75%',
          maxWidth: '888px',
        },
      },
      //to implement if needed
      // left: {
      // },
      right: {
        maxWidth: '480px',
        '@md': {
          maxWidth: '720px',
        },
      },
    },
  },
  all: 'unset',
  display: 'block',
  '&[data-state="open"]': {
    animation: `${slideIn} 300ms cubic-bezier(0.16, 1, 0.3, 1)`,
  },
  '&[data-state="closed"]': {
    animation: `${swipeOut} 300ms ease-out`,
  },
  '&[data-swipe="move"]': {
    transform: 'translateY(var(--radix-toast-swipe-move-y))',
  },
  '&[data-swipe="cancel"]': {
    transform: 'translateY(0)',
    transition: 'transform 200ms ease-out',
  },
  '&[data-swipe="end"]': {
    animation: `${hide} 100ms ease-out`,
  },
});
const StyledTitle = styled(Title, {});
const StyledDescription = styled(Description, {});
const StyledAction = styled(Action, {
  all: 'unset',
});
const StyledBannerContainer = styled(BannerContainer, {
  boxShadow: '0 16px 24px -8px rgb(0 0 0 / 28%)',
});
const StyledViewport = styled(Viewport, {
  position: 'fixed',
  right: 0,
  bottom: 0,
  display: 'flex',
  flexDirection: 'column',
  padding: '25px',
  gap: 10,
  maxWidth: '100vw',
  margin: 0,
  listStyle: 'none',
  zIndex: 2147483647,
  outline: 'none',
});
const StyledClose = styled(Close, {});

export const SnackbarToast = forwardRef<HTMLLIElement, SnackbarToastProps>(
  (
    {
      duration,
      label,
      swipeDirection,
      swipeThreshold,
      trigger,
      toastTitle,
      actions,
      closeAction,
      children,
      icon,
      customIcon,
      badgeContent,
      variant,
      look,
      size,
      container,
      ...rest
    },
    forwardedRef,
  ) => {
    return (
      <StyledProvider
        duration={duration}
        label={label}
        swipeDirection={swipeDirection}
        swipeThreshold={swipeThreshold}
      >
        <StyledRoot {...rest} ref={forwardedRef}>
          <StyledBannerContainer>
            <BannerContent
              icon={icon}
              customIcon={customIcon}
              badgeContent={badgeContent}
              variant={variant}
              look={look}
              size={size}
              actions={
                !!actions ? (
                  Array.isArray(actions) ? (
                    actions.map(({ altText, content }, index) => (
                      <StyledAction key={index} altText={altText}>
                        {content}
                      </StyledAction>
                    ))
                  ) : (
                    <StyledAction altText={actions.altText}>
                      {actions.content}
                    </StyledAction>
                  )
                ) : closeAction ? (
                  <StyledClose asChild>{closeAction}</StyledClose>
                ) : null
              }
            >
              <VStack>
                {toastTitle && <StyledTitle asChild>{toastTitle}</StyledTitle>}
                {children && (
                  <StyledDescription asChild>{children}</StyledDescription>
                )}
              </VStack>
            </BannerContent>
          </StyledBannerContainer>
        </StyledRoot>
        <StyledViewport />
      </StyledProvider>
    );
  },
);
SnackbarToast.displayName = 'SnackbarToast';
