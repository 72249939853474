import {
  AsProps,
  css,
  CSSProps,
  keyframes,
  styled,
  VariantProps,
} from '@neui/core';
import { Box, Flex } from '@neui/layout';
import { communication___chat } from '@neui/styleguide-commerzbank';
import { HTMLAttributes, useEffect, useState } from 'react';

import { Icon } from '@components/neui-components/atoms/Icon';

import { selectChatProps, useChatStore } from './store/store';

export type ChatMinizedProps = HTMLAttributes<HTMLButtonElement> &
  VariantProps<typeof buttonStyles> &
  CSSProps &
  AsProps & {
    isChatMinimized: boolean;
  };

const ThumbnailWrapper = styled(Flex, {
  borderRadius: '50%',
  display: 'inline-flex',
  aspectRatio: '1 / 1',
  transition: 'all 300ms ease-in-out',
  overflow: 'hidden',
  '&:not(:disabled)': {
    cursor: 'pointer',
  },
  boxShadow: '0px 2px 4px -1px rgba(0, 0, 0, 0.36)',
});

const fadeIn = keyframes({
  '0%': {
    display: 'flex !important',
    opacity: 0,
    transform: 'scale(1.5)',
    transformOrigin: 'bottom right',
  },
  '100%': {
    opacity: 1,
    transform: 'scale(1)',
    transformOrigin: 'bottom right',
  },
});

const fadeOut = keyframes({
  '0%': {
    display: 'block',
    opacity: 1,
    transform: 'scale(1)',
    transformOrigin: 'bottom right',
  },
  '99%': {
    opacity: 0,
    transform: 'scale(1.5)',
    transformOrigin: 'bottom right',
  },
  '100%': { display: 'none !important' },
});

const buttonStyles = css({
  display: 'none',
  position: 'fixed',
  zIndex: 1000,
  bottom: 32,
  right: 16,
  height: 'auto',
  textDecoration: 'none',
  flexDirection: 'column',
  alignItems: 'center',
  gap: 8,
  background: 'none',
  color: 'inherit',
  border: 'none',
  padding: 0,
  font: 'inherit',
  outline: 'inherit',

  [`& ${ThumbnailWrapper}`]: {
    backgroundColor: '$colors$primary',
    boxShadow: '0px 2px 4px -1px rgba(0, 0, 0, 0.36)',
  },
  '&:hover': {
    [`& ${ThumbnailWrapper}`]: {
      backgroundColor: '$colors$primary-hover',
    },
  },
  '&:focus-visible': {
    [`& ${ThumbnailWrapper}`]: {
      transition: 'none',
      outline: '2px solid $text-standard',
      outlineOffset: 4,
    },
  },
  '&:active': {
    [`& ${ThumbnailWrapper}`]: {
      backgroundColor: '$colors$primary-hover',
    },
  },
  variants: {
    state: {
      opening: {
        display: 'flex !important',
        animation: `${fadeIn} 240ms cubic-bezier(0.34, 0.63, 0, 1)`,
      },
      closing: {
        animation: `${fadeOut} 240ms cubic-bezier(0.34, 0.63, 0, 1)`,
      },
    },
  },
});

const StyledIcon = styled(Icon, {
  margin: 18,
});

const StyledComponent = styled('button', buttonStyles);

export const ChatMinimized = ({
  isChatMinimized,
  ...rest
}: ChatMinizedProps) => {
  const { unreadMsgs } = useChatStore(selectChatProps);
  const [animate, setAnimate] = useState(false);
  useEffect(() => {
    if (unreadMsgs > 0) {
      setAnimate(true);
      setTimeout(() => {
        setAnimate(false);
      }, 450);
    }
  }, [unreadMsgs]);

  return (
    <StyledComponent
      {...rest}
      state={isChatMinimized ? 'opening' : 'closing'}
      aria-label="Chat öffnen"
    >
      {unreadMsgs > 0 && (
        <BadgeContainer rotateBadge={animate}>
          <StyledTypography>{unreadMsgs}</StyledTypography>
        </BadgeContainer>
      )}

      <ThumbnailWrapper alignItems="center" justifyContent="center">
        <StyledIcon
          icon={communication___chat}
          aria-hidden="true"
          variant={'outline'}
          size="small"
        />
      </ThumbnailWrapper>
    </StyledComponent>
  );
};

const rotation = keyframes({
  '0%': { transform: 'rotate(0deg)' },
  '12.5%': { transform: 'rotate(9deg)' },
  '37.5%': { transform: 'rotate(-9deg)' },
  '62.5%': { transform: 'rotate(9deg)' },
  '87.5%': { transform: 'rotate(-9deg)' },
  '100%': { transform: 'rotate(0deg)' },
});
const BadgeContainer = styled(Box, {
  borderRadius: '$space$component-2',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  paddingX: '$component-1',
  background: '$text-standard',
  position: 'absolute',
  top: '-4px',
  right: '-4px',
  height: 20,
  minWidth: 23,
  width: 'fit-content',
  variants: {
    rotateBadge: {
      true: {
        transformOrigin: 'bottom center',
        animation: `${rotation} 450ms ease-in-out`,
      },
      false: {},
    },
  },
});

const StyledTypography = styled('div', {
  width: 'unset',
  height: 'unset',
  color: '$text-button-inverse !important',
  fontSize: '$fontSizes$mobile-7 !important',
  textAlign: 'center !important',
  fontFamily: '$sans',
});
