import React from 'react';
import { uml, umlauts, umlautsReplacements } from '@cds/utils';

import {
  ChipsReducer,
  StyledLi,
  StyledUl,
} from '@components/CdsStyledComponents';
import { Chip } from '@components/neui-components/atoms/Chip';
import { useTracker } from '@utils/snowplowTracking';
import {
  GA4PageType,
  GA4SearchType,
  GA4TrackInternalSearch,
} from '@utils/tracking';
import { useRuntimeSettings } from '@utils/config';

type SuggestionChipsType = {
  mostSearchedTerms?: string[];
  pageType: GA4PageType;
  executeSearch: (query: string, searchType: GA4SearchType) => Promise<void>;
};

export function SuggestionChips({
  mostSearchedTerms,
  pageType,
  executeSearch,
}: SuggestionChipsType): React.ReactElement {
  const {
    tracking: {
      ga4: { enabled: enableGA4Tracking },
    },
  } = useRuntimeSettings();
  const { trackButtonClick } = useTracker(SuggestionChips.name);
  const searchType: GA4SearchType = 'Default suggestion';

  return (
    <ChipsReducer>
      <StyledUl>
        {mostSearchedTerms?.map((label, index) => {
          const labelWithoutUmlaut = label.replace(uml, (c) => {
            return umlautsReplacements[umlauts.indexOf(c)];
          });
          return (
            <StyledLi key={index}>
              <Chip
                data-cy={'search-term'}
                onClick={() => {
                  trackButtonClick?.(label, 'search_term', []);
                  enableGA4Tracking &&
                    GA4TrackInternalSearch(
                      labelWithoutUmlaut,
                      searchType,
                      pageType,
                      index + 1,
                    );

                  executeSearch(label, searchType);
                }}
              >
                {label}
              </Chip>
            </StyledLi>
          );
        })}
      </StyledUl>
    </ChipsReducer>
  );
}
