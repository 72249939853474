import { Text, Typography } from '@neui/styleguide-commerzbank';
import { Box, VStack } from '@neui/layout';
import { styled } from '@neui/core';
import { useState } from 'react';

import { ChatAuthor, ChatMessage } from './ChatMessage';
import { ChatMessageType } from './store/store';
import {
  formatMsgTime,
  isGenericMessageContent,
  parseMsgValue,
} from './utils/utils';
import { GenericComponentRenderer } from './GenericComponentRenderer';

const GenericStructuredChatMessage = styled(VStack, {
  variants: {
    isClickable: {
      true: {
        cursor: 'pointer',
      },
      false: {},
    },
  },
});

const Container = styled(Box, {
  borderRadius: 8,
  width: 'fit-content',
  hyphens: 'auto',
  overflow: 'hidden',
  alignItems: 'flex-start',
  background: '$colors$petrol100',
});

type MessageRendererProps = {
  msg: ChatMessageType;
  shouldHideAuthor: boolean;
  srMsgAuthor?: 'user' | 'chat';
  scrollToBottom: () => void;
};
export function MessageRenderer({
  msg,
  shouldHideAuthor,
  srMsgAuthor,
  scrollToBottom,
}: MessageRendererProps) {
  const now = new Date().getTime();

  const [visible, setVisible] = useState(now >= (msg.renderAt ?? 0));

  if (!visible) {
    setTimeout(() => setVisible(true), (msg.renderAt ?? 0) - now);

    return null;
  }

  const { value } = parseMsgValue(msg.value);

  if (isGenericMessageContent(value)) {
    const hasComponents = value?.content?.some(
      (c) => c?.components && c?.components.length > 0,
    );

    const time = formatMsgTime(msg.timestamp);
    const signature =
      msg.displayName === 'Bot#Commerzbank ChatBot'
        ? 'Chatbot'
        : msg.displayName;
    return (
      <>
        <GenericStructuredChatMessage spacing={4} isClickable={hasComponents}>
          <Container>
            {value?.content?.map((c, i) => (
              <VStack spacing={4} key={i}>
                {c?.components && c?.components.length > 0 ? (
                  c?.components?.map((component, i) => (
                    <GenericComponentRenderer
                      key={i}
                      content={c}
                      component={component}
                    />
                  ))
                ) : (
                  <StyledTypography size={6}>{c.desc}</StyledTypography>
                )}
              </VStack>
            ))}
          </Container>
          {!shouldHideAuthor && (
            <ChatAuthor
              aria-hidden="true"
              type={'helper'}
            >{`${signature} - ${time}`}</ChatAuthor>
          )}
        </GenericStructuredChatMessage>
      </>
    );
  }

  if (msg.displayName === 'SYSTEM') {
    return (
      <StyledText type={'helper'} textAlign="center">
        {msg.value}
      </StyledText>
    );
  }

  return (
    <>
      <ChatMessage
        key={msg.sequenceNumber}
        participantId={msg.participantId}
        displayName={msg.displayName}
        shouldHideAuthor={shouldHideAuthor}
        timestamp={msg.timestamp}
        sequenceNumber={msg.sequenceNumber}
        msgValue={msg.value}
        scrollToBottom={scrollToBottom}
      >
        <Typography size={6}>
          <SrOnly>
            {srMsgAuthor === 'user' ? 'Sie sagen: ' : 'Bene sagt: '}
          </SrOnly>
          {value as string}
        </Typography>
      </ChatMessage>
    </>
  );
}

const StyledTypography = styled(Typography, {
  padding: '12px 16px',
});

const StyledText = styled(Text, {
  marginBottom: 12,
});

export const SrOnly = styled('span', {
  height: 1,
  width: 1,
  overflow: 'hidden',
  padding: 0,
  position: 'absolute',
  clip: 'rect(0, 0, 0, 0)',
  margin: -1,
  whiteSpace: 'nowrap',
});
