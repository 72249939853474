import { forwardRef } from 'react';
import { styled } from '@neui/core';

import { Link, LinkProps } from './Link';

type PaginationButtonProps = LinkProps & {
  label: string | number;
  isActive?: boolean;
};

export const PaginationButton = forwardRef<
  HTMLLIElement,
  PaginationButtonProps
>(
  (
    {
      isActive = false,
      href,
      label,
      renderAs = href ? 'a' : 'button',
      ...rest
    },
    forwardedRef,
  ) => {
    return (
      <StyledList isActive={isActive} role="listitem" ref={forwardedRef}>
        <StyledLink
          {...rest}
          href={href}
          renderAs={renderAs}
          isActive={isActive}
          aria-current={isActive}
          size={6}
          tabIndex={isActive ? -1 : 0}
        >
          {label}
        </StyledLink>
      </StyledList>
    );
  },
);

PaginationButton.displayName = 'PaginationButton';

const StyledLink = styled(Link, {
  width: '100%',
  textAlign: 'center',
  variants: {
    isActive: {
      true: {
        '& span': {
          position: 'relative',
        },
        '& span::after': {
          content: '',
          width: '100%',
          height: '2px',
          backgroundColor: '$text-standard',
          position: 'absolute',
          left: 0,
          bottom: '-2px',
        },
        '&:hover:not([active])': {
          '& span': {
            color: '$text-button !important',
          },
        },
        '&:focus:not([active])': {
          '& span': {
            color: '$text-button !important',
          },
        },
      },
      false: {
        display: 'block',
        '& span': {
          color: '#506C74 !important',
        },
        '&:hover:not([disabled])': {
          '& span': {
            color: '$neutral600 !important',
          },
        },
        '&:focus-visible:not([disabled])': {
          outline: '2px solid $text-standard',
          outlineOffset: 4,
          border: 'none',
          borderRadius: 4,
          '& span': {
            color: '$neutral600 !important',
          },
        },
      },
    },
  },
});

const StyledList = styled('li', {
  height: 24,
  width: 24,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  listStyle: 'none',
  '& span': {
    transition: 'color 0.1s ease-in-out',
  },
  variants: {
    isActive: {
      true: {},
      false: {
        display: 'none',
        '@sm': {
          display: 'flex',
        },
      },
    },
  },
});
