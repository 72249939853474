import { styled } from '@neui/core';
import { HStack, VStack } from '@neui/layout';
import { Typography } from '@neui/styleguide-commerzbank';
import {
  Banner,
  BannerProps,
} from '@components/neui-components/molecules/Banner';
import { baseTheme, oceanElevatedTheme } from 'styling/stitches.config';

type WarningMessageProps = BannerProps & {
  message: string;
};

export function WarningMessage({ message, ...rest }: WarningMessageProps) {
  return (
    <WarningMessageContainer>
      <Banner className={`${baseTheme} ${oceanElevatedTheme}`} {...rest}>
        <Container>
          <Typography size={6}>{message}</Typography>
        </Container>
      </Banner>
    </WarningMessageContainer>
  );
}

const WarningMessageContainer = styled(VStack, {
  padding: '0px 16px',
});

const Container = styled(HStack, {
  alignItems: 'center',
  gap: 8,
  justifyContent: 'space-between',
  width: '100%',
});
