import { StructuredButtonMessage } from './StructuredButtonMessage';
import { GenericComponentType, GenericStructuredMessage } from './utils/utils';

type GenericComponentRendererProps = {
  content: Omit<GenericStructuredMessage['content'][number], 'components'>;
  component: GenericComponentType;
};
export function GenericComponentRenderer({
  content,
  component,
}: GenericComponentRendererProps) {
  function renderer(componentType: string) {
    switch (componentType) {
      case 'button':
        return (
          <StructuredButtonMessage
            image={content.image}
            title={content.title}
            component={component}
          />
        );
      default:
        return <></>;
    }
  }
  return <>{renderer(component.type)}</>;
}
