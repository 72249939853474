import { styled } from '@neui/core';
import { VStack } from '@neui/layout';
import { Headline } from '@neui/styleguide-commerzbank';
import React, { PropsWithChildren } from 'react';

import { Button } from '@components/neui-components/atoms/Button';
import { Image } from '@components/Image';

type WarningScreenProps = {
  imgSrc?: string;
  imgAlt?: string;
  headline: string;
  buttonLabel?: string;
  imgComponent?: React.ReactNode;
  onButtonClick?: () => void;
};

export function WarningScreen({
  imgSrc,
  imgAlt,
  headline,
  buttonLabel,
  onButtonClick,
  imgComponent,
  children,
}: PropsWithChildren<WarningScreenProps>) {
  return (
    <Container>
      <VStack alignItems={'center'}>
        {imgSrc ? (
          <Image src={imgSrc} alt={imgAlt ? imgAlt : ''} />
        ) : imgComponent !== undefined ? (
          <>{imgComponent}</>
        ) : (
          <></>
        )}
      </VStack>
      <VStack spacing={24} alignItems={'center'}>
        <VStack alignItems={'center'} spacing={12}>
          <Headline type="h5">{headline}</Headline>
          {children}
        </VStack>
        {buttonLabel && (
          <StyledButton onClick={onButtonClick}>{buttonLabel}</StyledButton>
        )}
      </VStack>
    </Container>
  );
}

const Container = styled(VStack, {
  padding: '48px 24px',
  width: '100%',
  height: '100%',
  '@sm': {
    padding: '48px 24px 72px 24px',
    justifyContent: 'center',
  },
});

const StyledButton = styled(Button, {
  textAlign: 'center !important',
  alignSelf: 'stretch',
  '@sm': {
    alignSelf: 'center',
  },
});
