import React from 'react';
import Autosuggest from 'react-autosuggest';
import { styled } from '@neui/core';
import { symbols___info, Typography } from '@neui/styleguide-commerzbank';
import { Box, VStack } from '@neui/layout';

import { useTranslation } from '@utils/i18n';
import { baseTheme, oceanTheme } from 'styling/stitches.config';
import { XColumnsGridItem } from 'page-templates/XColumnsGridItem';
import { Section } from '@components/neui-components/atoms/Section';
import { StyledBanner } from '@components/CdsStyledComponents';
import { BannerBadgeLook } from '@components/neui-components/atoms/BannerTextBadge';

interface CdsSuggestionsContainerProps {
  containerProps: Autosuggest.RenderSuggestionsContainerParams['containerProps'];
  children: React.ReactNode;
  variant?: 'ocean' | 'base';
  wide?: boolean;
  iconLook?: BannerBadgeLook;
}

export const CdsSuggestionsContainer: React.FC<
  CdsSuggestionsContainerProps
> = ({ containerProps, children, wide, variant, iconLook }) => {
  const { $t } = useTranslation();

  if (React.Children.count(children) === 0) {
    return <></>;
  }

  const childrenArray = React.Children.toArray(children);

  // const links = React.Children.count(children) > 1 && (
  //   <Box css={{ marginTop: 24 }}>{childrenArray[1]}</Box>
  // );
  return (
    <SuggestionsWrapper
      {...containerProps}
      className={`${baseTheme} ${variant !== 'base' && oceanTheme}`}
      css={{ paddingBottom: wide ? 0 : '28px' }}
    >
      <VStack alignItems={'center'}>
        <XColumnsGridItem columns={{ base: 12, md: wide ? 12 : 8 }}>
          <VStack css={{ width: '100%' }}>
            <Box css={{ width: '100%' }} data-cy={'search-suggestions'}>
              {childrenArray[0]}
            </Box>
            <StyledBanner
              icon={symbols___info}
              variant="solid"
              css={{ marginTop: 16 }}
              look={iconLook}
            >
              <Typography size={6}>{$t('PERSONAL_DATA_DISCLAIMER')}</Typography>
            </StyledBanner>
          </VStack>
        </XColumnsGridItem>
      </VStack>
    </SuggestionsWrapper>
  );
};

const SuggestionsWrapper = styled(Section, {
  paddingTop: '0px !important',
});
