import React from 'react';

function ErrorIcon(): React.ReactElement {
  return (
    <svg
      width="192"
      height="193"
      viewBox="0 0 192 193"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3633_31359)">
        <path
          opacity="0.3"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M45.9629 93.6531L48.7913 90.8247L55.4381 97.4715L52.6097 100.3L45.9629 93.6531ZM41 108.5H51V112.5H41V108.5ZM52.6156 120.709L45.9688 127.356L48.7972 130.185L55.444 123.538L52.6156 120.709Z"
          fill="#002E3C"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M140 128.08C139.782 128.08 139.565 128.06 139.35 128.02L66.0602 115.1C64.7388 114.868 63.6408 113.95 63.179 112.691C62.7172 111.431 62.9616 110.021 63.8202 108.99L110.82 52.2501C111.692 51.193 112.99 50.5805 114.36 50.5801C114.628 50.5835 114.896 50.6069 115.16 50.6501C116.79 50.9433 118.138 52.0885 118.69 53.6501L143.49 123.06C143.891 124.208 143.712 125.48 143.009 126.473C142.306 127.466 141.167 128.057 139.95 128.06L140 128.08Z"
          fill="#002E3C"
        />
        <path
          opacity="0.3"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M107.78 49.7001C109.413 47.7273 111.839 46.5838 114.4 46.5801C114.9 46.5816 115.398 46.6284 115.89 46.7201C118.936 47.2502 121.461 49.3781 122.5 52.2901L147.34 121.7C148.267 124.305 147.741 127.206 145.958 129.318C144.174 131.431 141.403 132.437 138.68 131.96L65.4097 119.04C62.6849 118.562 60.4207 116.67 59.4665 114.073C58.5124 111.477 59.0127 108.569 60.7797 106.44L107.78 49.7001ZM115.199 50.6501C114.935 50.6065 114.667 50.5831 114.399 50.5801H114.359C112.989 50.5805 111.691 51.193 110.819 52.2501L63.8192 108.99C62.9606 110.021 62.7163 111.431 63.1781 112.691C63.6398 113.95 64.7378 114.868 66.0592 115.1L139.329 128C139.547 128.04 139.768 128.06 139.989 128.06C141.206 128.057 142.345 127.466 143.048 126.473C143.751 125.48 143.93 124.208 143.529 123.06L118.729 53.6501C118.177 52.0885 116.829 50.9433 115.199 50.6501Z"
          fill="#002E3C"
        />
        <rect
          opacity="0.3"
          x="104.9"
          y="97.5908"
          width="26"
          height="4"
          transform="rotate(-80 104.9 97.5908)"
          fill="#002E3C"
        />
        <path
          opacity="0.3"
          d="M108.72 110.44C108.333 112.615 106.256 114.064 104.082 113.677C101.907 113.291 100.457 111.215 100.843 109.04C101.23 106.865 103.305 105.415 105.48 105.8C106.525 105.986 107.454 106.579 108.062 107.449C108.669 108.319 108.906 109.395 108.72 110.44Z"
          fill="#002E3C"
        />
        <path
          opacity="0.3"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M52 150.5H60V154.5H52V150.5ZM64 150.5H142V154.5H64V150.5ZM150 150.5H146V154.5H150V150.5Z"
          fill="#002E3C"
        />
      </g>
      <defs>
        <clipPath id="clip0_3633_31359">
          <rect
            width="192"
            height="192"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default ErrorIcon;
