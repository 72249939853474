import React from 'react';

function SearchInteractionIcon(): React.ReactElement {
  return (
    <svg
      width="192"
      height="192"
      viewBox="0 0 192 192"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.3"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M90 150H98V154H90V150ZM152 150H156V154H152V150ZM148 150H102V154H148V150Z"
        fill="#002E3C"
      />
      <path
        opacity="0.1"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M62.1406 87C62.1406 102.464 74.6767 115 90.1406 115V113C104.5 113 116.141 101.359 116.141 87H118.141C118.141 71.536 105.605 59 90.1406 59V61C75.7812 61 64.1406 72.6406 64.1406 87H62.1406ZM70.53 90.8983C72.3896 100.256 80.6 106.997 90.1406 107C95.4511 107.017 100.547 104.907 104.291 101.14C111.036 94.3927 112.076 83.8204 106.775 75.8879C101.474 67.9553 91.3086 64.8714 82.4939 68.5219C73.6793 72.1723 68.6703 81.5406 70.53 90.8983Z"
        fill="#002E3C"
      />
      <path
        opacity="0.2"
        d="M90.1406 115V113C104.5 113 116.141 101.359 116.141 87H118.141C118.141 102.464 105.605 115 90.1406 115Z"
        fill="#002E3C"
      />
      <path
        opacity="0.5"
        d="M64.1406 87H62.1406C62.1406 71.536 74.6767 59 90.1406 59V61C75.7812 61 64.1406 72.6406 64.1406 87Z"
        fill="white"
      />
      <path
        opacity="0.1"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M114.141 119.34V111H122.491L142.491 131L134.141 139.34L114.141 119.34Z"
        fill="#002E3C"
      />
      <rect
        opacity="0.2"
        x="130.438"
        y="135.292"
        width="11.31"
        height="2"
        transform="rotate(-45 130.438 135.292)"
        fill="#002E3C"
      />
      <path
        opacity="0.3"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M115.139 107H124.139L148.139 131L134.139 145L110.139 121V112C96.8755 122.61 77.6333 121.008 66.3058 108.352C54.9783 95.6952 55.5127 76.3938 67.523 64.3834C79.5334 52.373 98.8348 51.8387 111.491 63.1661C124.148 74.4936 125.749 93.7359 115.139 107ZM62.1393 86.9997C62.1393 102.464 74.6754 115 90.1393 115C105.603 115 118.139 102.464 118.139 86.9997C118.139 71.5357 105.603 58.9997 90.1393 58.9997C74.6754 58.9997 62.1393 71.5357 62.1393 86.9997ZM114.139 119.34V111H122.489L142.489 131L134.139 139.34L114.139 119.34ZM105.699 71.44C97.1066 62.8514 83.1788 62.8536 74.5889 71.4449C65.999 80.0361 65.999 93.9639 74.5889 102.555C83.1788 111.146 97.1066 111.149 105.699 102.56C109.827 98.4339 112.146 92.8366 112.146 87C112.146 81.1634 109.827 75.5661 105.699 71.44ZM104.289 101.14C98.5697 106.861 89.9669 108.573 82.4926 105.478C75.0184 102.383 70.1449 95.0898 70.1449 87C70.1449 78.9102 75.0184 71.6171 82.4926 68.5218C89.9669 65.4266 98.5697 67.1388 104.289 72.86C108.066 76.5961 110.191 81.6877 110.191 87C110.191 92.3123 108.066 97.4039 104.289 101.14Z"
        fill="#002E3C"
      />
      <path
        opacity="0.1"
        d="M76.1406 87H74.1406C74.1406 78.1634 81.3041 71 90.1406 71V73C82.4086 73 76.1406 79.268 76.1406 87Z"
        fill="#002E3C"
      />
      <path
        opacity="0.3"
        d="M76.1406 87H74.1406C74.1406 78.1634 81.3041 71 90.1406 71V73C82.4086 73 76.1406 79.268 76.1406 87Z"
        fill="white"
      />
      <path
        opacity="0.3"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M39.9707 69.1536L42.7991 66.3252L49.4459 72.972L46.6175 75.8004L39.9707 69.1536ZM35 84.0003H44.4V88.0003H35V84.0003ZM46.6136 96.2109L39.9668 102.858L42.7952 105.686L49.442 99.0393L46.6136 96.2109Z"
        fill="#002E3C"
      />
    </svg>
  );
}

export default SearchInteractionIcon;
