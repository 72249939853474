import { location___globe } from '@neui/styleguide-commerzbank';
import { useRuntimeSettings } from '@utils/config';
import { GA4TrackNavigationClick } from '@utils/tracking';

import { NavLink } from '../NavItems/CdsNavItems';

export function LanguageSwitch() {
  const {
    staticContent: { alternativeLanguageDomains },
  } = useRuntimeSettings();

  if (
    alternativeLanguageDomains === undefined ||
    alternativeLanguageDomains.length === 0
  ) {
    return null;
  }

  return (
    <>
      {(alternativeLanguageDomains ?? []).map(
        ({ language, portalUrl }, index) => {
          return (
            <NavLink
              key={`language-switch-${index}`}
              isLanguageSwitch={true}
              label={language.toUpperCase()}
              icon={location___globe}
              onClick={() => {
                location.href = portalUrl;
                GA4TrackNavigationClick(
                  'mainNavigationClick',
                  'quick_access',
                  language.toUpperCase(),
                  portalUrl,
                );
              }}
            />
          );
        },
      )}
    </>
  );
}
