import { AsProps, CSSProps, styled } from '@neui/core';
import { Box, HStack, VStack } from '@neui/layout';
import { forwardRef, HTMLAttributes, ReactNode } from 'react';

import {
  bannerBadgeContainerStyle,
  BannerIconBadge,
  BannerIconBadgeProps,
} from './BannerIconBadge';
import { BannerTextBadge, BannerTextBadgeProps } from './BannerTextBadge';

export type BannerContentProps = AsProps &
  CSSProps &
  BannerIconBadgeProps &
  BannerTextBadgeProps &
  HTMLAttributes<HTMLDivElement> & {
    customIcon?: ReactNode;
    badgeContent?: string | ReactNode;
    actions?: ReactNode;
  };

const Container = styled(HStack, {
  width: '100%',
});

const IconContainer = styled(Box, {
  width: 18,
  height: 18,
  '& svg': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

const CustomIconContainer = styled(Box, bannerBadgeContainerStyle, {
  borderRadius: '$space$component-2',
  display: 'inline-flex',
  justifyContent: 'center',
  alignItems: 'center',
  paddingX: '$component-2',
  paddingY: 1,
  height: 'min-content',
  variants: {
    size: {
      small: {
        [`& ${IconContainer}`]: {
          padding: 3,
        },
      },
      large: {
        padding: '$component-3',
      },
    },
  },
});

export const BannerContent = forwardRef<HTMLDivElement, BannerContentProps>(
  (
    {
      customIcon,
      icon,
      badgeContent,
      variant = 'outline',
      look = 'supplementary',
      actions,
      size,
      children,
      ...rest
    },
    forwardedRef,
  ) => {
    return (
      <Container ref={forwardedRef} spacing="$component-4" {...rest}>
        {customIcon ? (
          <CustomIconContainer look={look} size={size}>
            <IconContainer>{customIcon}</IconContainer>
          </CustomIconContainer>
        ) : icon ? (
          <BannerIconBadge
            size={size}
            variant={variant}
            icon={icon}
            look={look}
          />
        ) : (
          <></>
        )}
        <VStack spacing="$component-4" css={{ width: '100%' }}>
          {badgeContent ? (
            <VStack alignItems="flex-start">
              <BannerTextBadge look={look}>{badgeContent}</BannerTextBadge>
              {children}
            </VStack>
          ) : (
            children
          )}
          {actions && (
            <HStack justifyContent="flex-end" spacing="$component-6">
              {actions}
            </HStack>
          )}
        </VStack>
      </Container>
    );
  },
);
BannerContent.displayName = 'BannerContent';
