import { ChatScreen } from './ChatScreen';
import { BrokenChatScreen } from './WarningScreens/BrokenChatScreen';
import { DatenschutzScreen } from './WarningScreens/DatenschutzScren';
import { NotAvailableScreen } from './WarningScreens/NotAvailableScreen';
import { selectChatProps, useChatStore } from './store/store';

export function ScreenStateRenderer() {
  const { userState } = useChatStore(selectChatProps);
  if (userState.state === -1 && !userState.hasConsented) {
    return <NotAvailableScreen />;
  }
  if (userState.status === 'online' && !userState.hasConsented) {
    return <DatenschutzScreen />;
  }
  if (userState.state === -1) {
    return <BrokenChatScreen />;
  }
  // if (userState.state === 3) {
  //   return <WaitingScreen waitingTime={'3 Minuten'} />;
  // }
  // if (userState.state === 4) {
  //   return <ChatOverScreen />;
  // }
  return <ChatScreen />;
}
