import { keyframes, styled } from '@neui/core';
import { Box, HStack } from '@neui/layout';

export function ChatTypingDots() {
  return (
    <Container>
      <Dots />
    </Container>
  );
}

const Container = styled(HStack, {
  position: 'relative',
  padding: '4px 16px',
  justifyContent: 'center',
  alignItems: 'center',
});

const dotFlashing = keyframes({
  '0%': { backgroundColor: '$colors$petrol300' },
  '50%': { backgroundColor: '$colors$petrol200' },
  '100%': { backgroundColor: '$colors$petrol200' },
});

const Dots = styled(Box, {
  position: 'relative',
  width: 10,
  height: 10,
  borderRadius: 5,
  backgroundColor: '$colors$petrol300',
  color: '$colors$petrol300',
  animation: `${dotFlashing} 1s infinite linear alternate`,
  animationDelay: '0.5s',
  '&:before, &:after': {
    content: '',
    display: 'inline-block',
    position: 'absolute',
    top: 0,
  },
  '&:before': {
    left: '-15px',
    width: 10,
    height: 10,
    borderRadius: 5,
    backgroundColor: '$colors$petrol300',
    color: '$colors$petrol300',
    animation: `${dotFlashing} 1s infinite alternate`,
    animationDelay: '0s',
  },
  '&:after': {
    left: 15,
    width: 10,
    height: 10,
    borderRadius: 5,
    backgroundColor: '$colors$petrol300',
    color: '$colors$petrol300',
    animation: `${dotFlashing} 1s infinite alternate`,
    animationDelay: '1s',
  },
});
