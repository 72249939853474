type IconProps = {
  fill: string;
};

export function SendIconSVG({ fill }: IconProps) {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22 11L0 0L2.76 11L0 22L22 11ZM4.57 12L9.67 12V10L4.57 10L3 3.74L17.53 11L3 18.26L4.57 12Z"
        // fill="#002E3C"
        fill={fill}
      />
    </svg>
  );
}
